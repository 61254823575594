.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.blockui {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.75);
}
.main-outer{
  position: relative;
  width: 100%;
  background: #efefef;
}
.main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100vh;
  font-size: 14px;
  color: #5f5f5f;
}
label{
  display: inline-block;
  width: auto;
  margin-bottom: 0.25em;
  font-weight: bold;
  color: #191919;
}

.main-outer *{
  box-sizing: border-box;
}
.panel-left{
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #efefef;
}
.panel-right{
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  border: 1px solid #cfcfcf;
  background-color: #fafafa;
}
.panel-right > div{
  padding: 0 1em;
}
.search-wrapper{
  margin-bottom: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.search-wrapper.editor-row .editor-cell:last-child{
  flex-grow: 2;
}
.elements-wrapper{
  margin-top: 0.4em;
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}
.draggable{
  display: flex;
  flex-direction: column;
  width: 48%;
  height: auto;
  margin-bottom: 0.8em;
  padding: 0.5em;
  border: 1px solid #cfcfcf;
  cursor: move;
}
.draggable.not-draggable{
  cursor: pointer;
}
.draggable:hover{
  background-color: #ffffff;
  box-shadow: 0 0 5px #efefef;
}
.draggable span{
  text-align: center;
  color: #3f3f3f;
}
.draggable img{
  width: 100%;
  height: auto;
}
.draggable i{
  font-size: 60px;
  line-height: 1;
  color: #9f9f9f;
}
.preview{
    width: 540px;
    min-height: 100vh;
    margin: 0 auto;
    border: 1px solid #afafaf;
    background: #ffffff;
}
.preview *{
  box-sizing: border-box;
}
.dragging{
  width: 72px;
  height: 72px;
  z-index: 1;
}
.fixed-drop{
  height: 4em;
  display: flex;
  font-size: 3em;
      display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  background-color: #cfcfcf;
}
.editor {
  width: 100%;
}

.message{
    padding: 0.85em 1.2em !important;
    display: flex;
    justify-content: space-between;
    background: #cfcfcf;
    color: #3d3d3d;
}
.message a{
    width: auto;
    background: rgba(0,0,0,0.15);
    text-align: center;
    padding: 0.4em;
    text-decoration: none;
    color: #191919;
}
.message.success{
    color: #131;
    background: #9c9;
}
.message.error{
    color: #300;
    background: #c99;
}
.message.info{
  color: rgb(44, 74, 104);
  background: #aacef1;
}
.message span{
  display: inline-block;
  width: auto;
}
.thumbnail{
  width: 5em;
  height: auto;
}
.img-responsive{
  max-width: 100%;
  height: auto;
}
.editor-btns{
  display: flex;
  justify-content: space-between;
}
.editor-btn{
  display: inline-block;
  outline: none !important;
  box-shadow: none;
  width: auto;
  height: auto;
  text-align: center;
  font-size: 14px;
  border-radius: 0;
  padding: 0.5em 0.75em;
  margin-right: 0.3em;
  border: 1px solid #cfcfcf;
  background-color: #efefef;
  color: #3f3f3f;
}
.editor-btn:hover{
  box-shadow: 0 0 3px rgba(0,0,0,0.45);
}
.editor-btn.btn-delete{
  border-color: #ee3a3a;
  background-color: #ee3a3a;
  color: #efefef;
}
.btn-block{
  display: block;
  margin: 0.4em 0;
  width: 100%;
}
.btn-sm {
  padding: 0.2em 0.35em;
  font-size: 0.8em;
}
.btn-primary{
  border-color: #61dafb;
  background-color: #61dafb;
  color: #191919;
}
.editor-btns .btn-block{
  width: auto;
}
.editor > *{
  padding: 0 1em;
}
.editor label{
  display: block;
  margin-bottom: 0.3em;
  margin-top: 0.75em;
}
.editor label a{
  text-decoration: none;
  display: inline-block;
  color: #04b9b9;
}
.editor h3{
  display: flex;
  justify-content: space-between;
  color: #191919;
  font-size: 1.25em;
  font-weight: normal;
}
.element-fields{
  border-top: 1px solid #efefef;
}
.editor label{
  font-weight: normal;
  color: #090909;
}
.editor .input, .panel-right .input{
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 0.4em 0.6em;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  border: 1px solid #dfdfdf;
  background-color: #efefef;
}
.editor .input:focus, .panel-right .input:focus{
  border-color: #61dafb;
  background-color: #ffffff;
}
.input-number-wrapper{
  display: flex;
}
.input-number-wrapper .input{
  flex-grow: 2;
}
.input-number-wrapper .input-btns{
  width: 36px;
}
.input-number-wrapper .editor-btn{
  margin-right: 0;
  width: 36px;
  height: 20px;
  padding: 2px;
  font-size: 10px;
  line-height: 1;
  color: #5f5f5f;
}
.editor-row{
  display: flex;
}
.editor-row.close{
  display: none;
}
.editor-row.open{
  display: flex;
}
.editor-cell{
  padding: 0 0.3em;
}
.editor-cell span{
  display: inline-block;
  margin-bottom: 0.3em;
}
.radio-inline{
  padding: 0 0.3em;
}
.color-input-wrapper{
  position: relative;
  width: 200px;
  margin-top: 0.4em;
}
.color-input-wrapper .btn-sm{
  position: absolute;
  top: 0;
  left: 0;
}
.tab{
  margin-top: 1em;
}
.tab-handle a{
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #3f3f3f;
  padding: 0.3em 0.25em;
  background-color: #f4f4f4;
}
.tab-body{
  padding: 0.8em 0;
}
.editor-row button{
  margin-top: 0.3em;
}
.pseudo-el{
  display: inline-block;
  width: auto;
  cursor: pointer;
}
.pseudo-el.div{
  display: block;
  width: 100%;
}
.pseudo-el .droppable{
  width: 100%;
    text-align: center;
    padding: 2em;
    background: #cfcfcf;
    border: 1px dashed #3faf3f;
    font-size: 1.4em;
}
.pseudo-el.editable:hover{
  border: 1px solid #c7da25;
}
.pseudo-el.active, .droppable.active{
  border: 1px solid #61dafb;
  /* box-shadow: 0 0 5px rgba(0,0,0,0.45); */
}
.pseudo-el.h1, .pseudo-el.h2, .pseudo-el.h3, .pseudo-el.h4{
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: bold;
  margin: 0.5em 0;
  color: #191919;
}
.pseudo-el.h1{
  font-size: 2.2em;
} 
.pseudo-el.h2{
  font-size: 1.8em;
} 
.pseudo-el.h3{
  font-size: 1.6em;
} 
.pseudo-el.h4{
  font-size: 1.3em;
}
.pseudo-el.paragraph{
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.4;
  padding: 1em;
}
.pseudo-el .img-responsive{
    width: 100%;
    height: auto;
}
.pseudo-el.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.pseudo-el.col-1{
  width: 100%;
}
.pseudo-el.col-2{
  width: 50%;
}
.pseudo-el.col-3{
  width: 33.3333%;
}
.pseudo-el.col-2-3{
  width: 66.6667%;
}
.pseudo-el.cell{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.pseudo-el.anchor{
  text-decoration: none;
  color: #04b9b9;
}
.pseudo-el.anchor:hover{
  text-decoration: none;
  color: #1ddbdb;
}
.pseudo-el.seperator{
  content: ' ';
  width: 4em;
  height: 0.4em;
  display: flex;
  margin: 1em auto;
  background-color: #3f3f3f;
}
.pseudo-el.spacer{
  content: ' ';
  width: 100%;
  height: 2em;
  display: flex;
  margin: auto;
}
.pseudo-el.button{
  width: auto;
  padding: 0.4em 1.2em;
  text-align: center;
  background-color: #04b9b9;
  color: #ffffff;
}
.pseudo-el.btn-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
.pseudo-el.image-box{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.pseudo-el.banner{
  display: flex;
  flex-direction: column;  
  width: 100%;
}
.pseudo-el.banner img{
  width: 100%;
  height: auto;
}
.pseudo-el.button-on-banner{
  position: relative;
  display: flex;
  flex-direction: column;  
  width: 100%;
}
.pseudo-el.button-on-banner .btn-wrapper{
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;
  margin-top: -2em;
  text-align: center;
}
.pseudo-el.text-on-banner{
  padding: 2em;
}
.pseudo-el.text-on-banner .block{
  padding: 1em;
}
.pseudo-el.text-on-banner .block .h2, .pseudo-el.text-on-banner .block .paragraph{
  margin-bottom: 1.5em;
}

.floating-right{
  position: fixed;
  z-index: 5;
  background: #ffffff;
  padding: 1em 1.2em;
  top: 4em;
  right: 20vw;
  width: 30vw;
}
.floating-right ul{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.floating-right ul li{
  width: 120px;
  margin: 0;
  margin-bottom: 1em;
  border: 1px solid #cfcfcf;
  background-color: #efefef;
}
.floating-right ul li a{
  display: block;
  text-decoration: none;
  padding: 0.6em 1em;
  text-align: center;
  color: #3f3f3f;
}
.floating-right ul li:hover{
  background: #ffffff;
}
.floating-right ul li a:hover{
  color: #191919;
}
.floating-right .editor-btn{
  position: absolute;
  right: 0;
  top: 0;
}
.eicon-btn-banner::before{
  content: '\e873';
}
.eicon-btn-banner::after{
  content: '\e81e';
  vertical-align: sub;
}
.gallery-wrapper{
  font-size: 12px;
}
.gallery-action-wrapper{
  display: flex;
  justify-content: space-between;
}
.gallery-action{
  width: 48%;
  padding: 0.7em 0.6em;
}
.gallery-action input{
  display: inline-block;
}
.gallery-action-wrapper .gallery-action:last-child{
  
}
.gallery-items-wrapper{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gallery-item{
  width: 24%;
  margin-bottom: 0.8em;
  padding: 0.4em;
  word-break: break-all;
  color: #5f5f5f;
  text-decoration: none !important;
  border: 1px solid transparent
}
.gallery-item:hover{
  border-color: #1ddbdb;
}
.gallery-item img{
  width: 100%;
  height: auto;
}
.editor-btns .btn-block{
  padding: 0.2em;
  width: 24.5%;
}
@media (max-width: 1280px){
  .hide-sm{
    display: inline;
    font-size: 12px;
    line-height: 20px;
  }
}